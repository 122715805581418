<template>
  <!-- FEATURES -->
  <section class="section bg-gray" id="features">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <div class="title-box">
            <h2 class="fadeIn animated wow" data-wow-delay=".1s">KURLABS</h2>
            <h4>MANAGER CONTROL</h4>
            <div class="border"></div>
          </div>
        </div>
      </div>
      <!-- end row -->

      <div class="row justify-content-between text-center">
        <div class="col-lg-3">
          <div class="service-item animated fadeInLeft wow card" data-wow-delay=".1s">
            <div class="service-detail card-body">
              <img
                src="../../assets/images/manage/control_manager_logs.png"
                width="120"
                alt="mouse-globe  "
                class="mb-3 card-image"
              />
              <h5>{{$t("Administration of the application, in a graphic and intuitive way.")}}</h5>
            </div>

            <!-- /service-detail -->
          </div>
          <!-- /service-item -->
        </div>
        <!-- /col -->

        <div class="col-lg-3">
          <div class="service-item animated fadeInLeft wow card" data-wow-delay=".1s">
            <div class="service-detail card-body">
              <img
                src="../../assets/images/manage/control_manager_use.png"
                width="120"
                alt="laptop-cloud"
                class="mb-4 card-image"
              />
              <h5>{{$t("Visualization of the resources used by the applications (CPU, RAM, Network).")}}</h5>
            </div>

            <!-- /service-detail -->
          </div>
          <!-- /service-item -->
        </div>
        <!-- /col -->

        <div class="col-lg-3">
          <div class="service-item animated fadeInLeft wow card" data-wow-delay=".1s">
            <div class="service-detail card-body">
              <img
                src="../../assets/images/manage/control_manager_logs2.png"
                width="120"
                alt="plug-cloud"
                class="mb-3 card-image"
              />
              <h5>{{$t("Log access without entering the server.")}}</h5>
              <br />
            </div>

            <!-- /service-detail -->
          </div>
          <!-- /service-item -->
        </div>
        <!-- /col -->
        <div class="col-lg-3">
          <div class="service-item animated fadeInLeft wow card" data-wow-delay=".1s">
            <div class="service-detail card-body">
              <img
                src="../../assets/images/manage/control_manager_shel.png"
                width="120"
                alt="plug-cloud"
                class="mb-3 card-image"
              />
              <h5>{{$t("Access to shell console without entering the server.")}}</h5>
              <br />
            </div>

            <!-- /service-detail -->
          </div>
          <!-- /service-item -->
        </div>
        <!-- /col -->
      </div>
      <!--end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- END FEATURES -->
</template>

<script>
export default {
  name: "ManageControlCard"
};
</script>


<style scoped>
.card-image {
  height: 80px !important;
  border: 1px solid lightgray;
}
</style>

